import HeroSection from "../components/hero-section/HeroSection"
import HeadContent from "../components/head-content/HeadContent";
import AudienceTag from "../components/audience-tag/AudienceTag";
import Maker from "../components/maker/Maker";
import RecBlock from "../components/modular-sections/rec-block/RecBlock";
import UgcBlock from "../components/modular-sections/ugc-block/UgcBLock";
import CategorySection from "../components/modular-sections/category-section/CategorySection";
import CalloutSection from "../components/modular-sections/callout-section/CalloutSection";
import MasonrySection from "../components/modular-sections/masonry-section/MasonrySection";

export {HeroSection, HeadContent, AudienceTag, Maker, RecBlock, UgcBlock, CategorySection, CalloutSection, MasonrySection }